import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { registerLicense } from "@syncfusion/ej2-base";

// Registering Syncfusion license key
// todo: get valid token that isnt trial
registerLicense(
  "ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmJBYVF2R2dJflRwcV9HZEwxOX1dQl9nSH9SfkRgWXlddXVcRWI="
);

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
